import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';

export default function OrgChartPage() {
  const [orgData, setOrgData] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);

  useEffect(() => {
    // Fetch organizational data from "/organization-chart" endpoint
    fetch('/organization-chart')
      .then((response) => {
        if (!response.ok) {
          console.log("API FAILURE");
        }
        return response.json();
      })
      .then((responseData) => {
        setOrgData(responseData.organization);
      })
      .catch((error) => {
        console.error('Error fetching organizational data:', error);
      });
  }, []);

  const toggleNode = (nodeName) => {
    console.log("toggling node")
    const isCurrentlyExpanded = isNodeExpanded(nodeName);
    if (isCurrentlyExpanded) {
      setExpandedNodes(expandedNodes.filter((node) => node !== nodeName));
    } else {
      setExpandedNodes([...expandedNodes, nodeName]);
    }
  };

  const isNodeExpanded = (nodeName) => expandedNodes.includes(nodeName);

  const renderEmployees = (employees, departmentName) => {
    return employees.map((employee) => (
      <TreeNode
        label={
          <div className="p-4 bg-white rounded shadow">
            <div className="font-bold text-lg">{employee.name}</div>
          </div>
        }
        key={employee.name}
        onClick={() => toggleNode(employee.name)}
        isExpanded={isNodeExpanded(employee.name)}
      >
        <div className="p-2">
          <p>Department: {employee.department}</p>
          <p>Salary: {employee.salary}</p>
          <p>Office: {employee.office}</p>
          <p>Skills: {employee.skills.join(', ')}</p>
        </div>
      </TreeNode>
    ));
  };

  return (
    <div className="container mx-auto mt-8 p-4 max-h-screen max-w-screen-lg">
      <h2 className="text-2xl font-semibold mb-4">Org Chart Page</h2>
      {orgData && (
        <Tree label={<div className="font-bold text-xl">Organization</div>}>
          {orgData.departments.map((department) => (
            <TreeNode
              label={
                <div className="p-4 bg-gray-200 rounded shadow">
                  <div className="font-semibold text-lg">{department.name}</div>
                </div>
              }
              key={department.name}
              onClick={() => toggleNode(department.name)}
              isExpanded={isNodeExpanded(department.name)}
            >
              {renderEmployees(department.employees, department.name)}
            </TreeNode>
          ))}
        </Tree>
      )}
    </div>
  );
}
