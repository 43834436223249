import React, { useEffect, useState } from 'react';

export default function HomePage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Make an HTTP GET request to the server-side code
    fetch('/me') // You should replace '/api/some-endpoint' with the actual endpoint you're using
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Render the data in a visually appealing way
  return (
    <div>
      {data && (
        <div>
          <h2>{data.name}</h2>
          <p>Homepage: <a href={data.homepage}>{data.homepage}</a></p>
          <p>GitHub: <a href={data.githubURL}>{data.githubURL}</a></p>
          <p>Interesting Fact: {data.interestingFact}</p>
          <p>Skills: {data.skills.join(', ')}</p>
        </div>
      )}
    </div>
  );
}