import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OrgChartPage from './components/org-chart';
import HomePage from './components/home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/org-chart" element={<OrgChartPage/>} />
        <Route exact path="/" element={<HomePage/>} />
      </Routes>
      
    </Router>
  );
}

export default App;
